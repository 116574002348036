import { APP_ROUTES } from '@lib/route/constants';
import { Tenant } from '@lib/tenants/types';
import { DEFAULT_DECIMAL_SCALE } from '@lib/price';
import { createHeadingStyle, createTextStyle } from '@lib/theme/utils';
import { fontWeightSizes } from '@lib/theme/themeSetup';

export const BASE_CONFIG: Tenant = {
  config: {
    setup: {
      id: {
        local: '2',
        demo: '2',
        qa: undefined,
        prod: undefined,
        staging: '1',
      },
      name: 'base',
      cmsSubdomain: {
        local: 'ocb-base-local',
        demo: 'ocb-base-demo',
        staging: 'ocb-base-staging',
        qa: undefined,
        prod: undefined,
      },
      ryftPublicKey: {
        local:
          'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
        demo: 'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
        qa: 'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
        prod: 'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
        staging:
          'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
      },
      stripePublicKey: {
        local:
          'pk_test_51Q7BHxRxmCxjBjUG7Xc5Ohc2eHlMocvsCNaubaC8JIzpiY3DAg0GU9BGJ5veo8wKlZQiClXQkEbNeqmgQNElv2ei00BZA5og4T',
        demo: 'pk_test_51Q7BHxRxmCxjBjUG7Xc5Ohc2eHlMocvsCNaubaC8JIzpiY3DAg0GU9BGJ5veo8wKlZQiClXQkEbNeqmgQNElv2ei00BZA5og4T',
        staging: undefined,
        prod: undefined,
        qa: undefined,
      },
      paypalPublicKey: {
        local:
          'AUXG7rcYCgh0FdnAkjsqLHpMXUY3bC73lZlcC0M-l4nVhGcCypXpRzwIOaP4rwoUoVy0vomHnzSTjkYA',
        demo: 'AUXG7rcYCgh0FdnAkjsqLHpMXUY3bC73lZlcC0M-l4nVhGcCypXpRzwIOaP4rwoUoVy0vomHnzSTjkYA',
        qa: 'AUXG7rcYCgh0FdnAkjsqLHpMXUY3bC73lZlcC0M-l4nVhGcCypXpRzwIOaP4rwoUoVy0vomHnzSTjkYA',
        prod: 'AUXG7rcYCgh0FdnAkjsqLHpMXUY3bC73lZlcC0M-l4nVhGcCypXpRzwIOaP4rwoUoVy0vomHnzSTjkYA',
        staging:
          'AUXG7rcYCgh0FdnAkjsqLHpMXUY3bC73lZlcC0M-l4nVhGcCypXpRzwIOaP4rwoUoVy0vomHnzSTjkYA',
      },
      authTenantId: {
        local: '8nw68yvb',
        demo: '8nw68yvb',
        staging: 'vbqx6g8n',
        qa: undefined,
        prod: undefined,
      },
      ocbDigitalBaseUrl: {
        demo: 'https://digital-demo.opencloudbss.com/digital/api',
        qa: undefined,
        prod: undefined,
        staging: 'https://digital-staging.opencloudbss.com/digital/api',
      },
      blueBoxChatBotUrl: {
        local: undefined,
        demo: undefined,
        qa: undefined,
        staging: undefined,
        prod: undefined,
      },
      decimalScale: {
        local: DEFAULT_DECIMAL_SCALE,
        demo: DEFAULT_DECIMAL_SCALE,
        qa: DEFAULT_DECIMAL_SCALE,
        staging: DEFAULT_DECIMAL_SCALE,
        prod: DEFAULT_DECIMAL_SCALE,
      },
    },
  },
  cmsRoutes: [
    {
      type: 'page',
      path: APP_ROUTES.ROOT_UID.path,
    },
    {
      type: 'home-page',
      path: APP_ROUTES.HOME.path,
    },
    {
      type: 'privacy-policy',
      path: APP_ROUTES.PRIVACY_POLICY.path,
    },
    {
      type: 'available_products',
      path: APP_ROUTES.PLANS.path,
    },
    {
      type: 'terms-and-conditions',
      path: APP_ROUTES.TERMS_AND_CONDITIONS.path,
    },
  ],
  theme: {
    typographyStyles: {
      h1: createHeadingStyle(96, 60, 40, 1, fontWeightSizes.fontWeightBold),
      h2: createHeadingStyle(64, 48, 36, 1, fontWeightSizes.fontWeightBold),
      h3: createHeadingStyle(40, 28, 20, 1, fontWeightSizes.fontWeightBold),
      h4: createHeadingStyle(36, 24, 18, 1.1, fontWeightSizes.fontWeightBold),
      h5: createHeadingStyle(24, 20, 16, 1.1, fontWeightSizes.fontWeightBold),
      h6: createHeadingStyle(20, 18, 16, 1.1, fontWeightSizes.fontWeightBold),
      paragraph: createTextStyle(18, 16, 14, 1.5),
    },
    componentStyles: {
      spacing: { xs: 2, sm: 3 },
      borderRadius: { xs: 6, sm: 10 },
    },
  },
  seo: {
    displayName: 'Metasite Base',
  },
};
